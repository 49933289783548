.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Center the form vertically on the page */
    /* You can add additional styles to the container if needed */
  }
  
.loginButton {
    width: 50%;
}